import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react'
import UseCases from '../templates/use-cases';

type ChildImagesharpType = {
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData
  }
}

export type imageDataType = {
  reasonImg: ChildImagesharpType,
  heroImg: ChildImagesharpType,
  dummyImg: ChildImagesharpType,
  // edgeAutomationImg: ChildImagesharpType,
  // unifiedSearchImg: ChildImagesharpType,
  // analyticImg: ChildImagesharpType,
  testimonialLogoOne: ChildImagesharpType,
  testimonialLogoTwo: ChildImagesharpType,
  contentWithImageOne: ChildImagesharpType,
  contentWithImageTwo: ChildImagesharpType,
}


function PreventEscalations({ data }: { data: imageDataType }) {

  const pageData = {
    seoData: {
      title: "Prevent Customer Support Escalations with GPT-Powered Answer Engine",
      canonicalLink: "https://www.aptedge.io/prevent-escalations/",
      metaDescription: {
        metaDescription: `Preventing customer support escalations optimizes operational expenses and reduces the need for extra agents. Get 35% instant reduction in escalations with Aptedge.`,
      },
      openGraphImage: {
        file: {
          url: "",
        },
      },
    },
    title: "Prevent Escalations",
    heroImage: data.heroImg.childImageSharp.gatsbyImageData,
    reasons: {
      title: "Why do you need to prevent escalations?",
      content: "An under-discussed metric in customer support operations is the cost of prevention. Every ticket and escalation prevented impacts the spending of customer support departments. More tickets require more agents who in turn need training, benefits, and recruiting efforts.",
      image: data.reasonImg.childImageSharp.gatsbyImageData,
    },
    testimonial: [
      {
        company: "everbridge",
        name: "Renee Bastine",
        bio: "Sr. Director, Global Customer Support",
        logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
        content: "Our CSAT has been 99% since we put AptEdge in use. With AptEdge we were able to get answers to our customers quickly and accurately."
      },
      {
        company: "catchpoint",
        name: "Rajesh Menon",
        bio: "EVP, Client Services",
        logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
        content: "With AptEdge, we can automate solution discovery, enabling TSEs to solve cases faster, cut escalations."
      },
    ],

    // howItWorks: {
    //   unified: data.unifiedSearchImg.childImageSharp.gatsbyImageData,
    //   edgeAutomation: data.edgeAutomationImg.childImageSharp.gatsbyImageData,
    //   analytic: data.analyticImg.childImageSharp.gatsbyImageData,

    // },
    dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    relatedCases: [
      {
        text: "Ticket Volume Reduction",
        link: "/ticket-volume-reduction/",
      },
      {
        text: "Improved CSAT",
        link: "/improved-csat/",
      },
      {
        text: "Improved Resolution Time",
        link: "/improved-resolution-time/",
      }
    ],
  }

  return (
    <UseCases pageData={pageData} />
  )
}

export default PreventEscalations;

export const query = graphql`
    query PreventEscalations {
        reasonImg: file(relativePath: { eq: "use-cases/prevent-escalations.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/logo-everbridge.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/catchpoint-logo.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
      heroImg: file(relativePath: { eq: "use-cases/hero-dummy.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE )
       }
     }

}`